section.industries .grid{
    gap: 40px;
}

section.industries .grid .offer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    text-align: center;
}

section.industries .grid .offer:last-child{
    // align-self: center;
    // margin-left: auto;
    // grid-column: span 3;
}

.hero.industries{
    .left,
    .right{
        width: 50%;
    }
}

section.industries .grid .offer img{
    max-width: 200px;
}

@media screen and (max-width: 1676px) {
    .hero.industries .left p:nth-child(1) {
        font-size: 60px;
    }
}

@media screen and (max-width: 1328px) {
    .hero.industries .left p:nth-child(1) {
      font-size: 50px;
    }
}

@media screen and (max-width: 980px) {
    .hero.industries{
        .left{
            width: 60%;

            p:nth-child(1){
                font-size: 45px;
            }
        }
        .right{
            width: 40%;
        }
    }
}

@media screen and (max-width: 876px) {
    .hero.industries .left p:nth-child(1) {
      font-size: 36px;
    }
}

@media screen and (max-width: 684px) {
    .hero.industries {
        
        .right{
            width: 100%;
            justify-content: center;

            img{
                width: 80%;
            }
        }

        .left {
            width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .hero.industries{
        padding-left: 50px;
        padding-right: 50px;
    }

    section.industries .grid .offer img{
        max-width: 150px;
    }
}