$primary-color: #005F6A;
$secondary-color: #54949B;
$accent-color: #E6EFF0;
$secondary-accent-color: #B0CDD1;
$heading-color: #B0CDD1;

$black-300: #545454;
$grey-500: #2A2929;

$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

$nav-font-size: 30px;
$hero-header-font-size: 80px;
$hero-sub-header-font-size: 51px;