.App {
  text-align: center;
  width: 100%;
  /* max-width: 1920px;
  position: relative;
  overflow: hidden; */
}

.App.grayed-out :not(header) {
  opacity: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
    margin: 0; /* Reset default margin */
}
a{
  text-decoration: none;
  color: inherit;
}
/* Main content style */
.main-content {
    flex: 1; /* Grows to take available space pushing the footer down */
}

/* Footer stays the same */
.footer {
    background-color: #ececec; /* Light grey background */
    padding: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.9em;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
