@import '/src/Styles.scss';
.hero-solutions{
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 100px 192px 0px 192px;
    justify-content: center;
}

.hero-solutions .background-pattern{
    margin-top: 150px;
    right: 0px;
    width: 100%;
}

.hero-solutions .left{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 700px;
}

hero-solutions .left p{
    margin: 0;
    text-align: left;
}

.hero-solutions .left p:nth-child(1){
    font-size: $hero-header-font-size;
    font-weight: $bold;
    color: $primary-color;
}

.hero-solutions .left p:nth-child(2){
    font-size: 40px;
    font-weight: $semi-bold;
    color: $secondary-color;
}

section#animated-projects {
    background-image: url(../assets/backgrounds/background-4.svg);
    background-size: auto 100%;
    box-sizing: border-box;
    padding-bottom: 137px;
    margin-bottom: 0px;
    margin-top: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

section#animated-projects .header{
    text-align: left;
    font-size: 50px;
    color: $primary-color;
    font-weight: $medium;
}

.project-slider{
    margin-top: 32px;
}

.project-card{
    text-align: left;
    position: relative;
    z-index: 0;
    border-radius: 16px;
}

.project-card .reference{
    width: 100%;
    margin-bottom: 12px;
    border-radius: 16px;
}

.project-card .title{
    font-size: 38px;
    font-weight: $medium;
    color: $primary-color;
}

.project-card .description{
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.content{
    text-align: left;
    font-size: 18px;
    margin-top: 24px;
}

section#industries{
    padding-top: 0px;
}

.my-swiper{
    position: relative;
}
.swiper{
    z-index: 0 !important;
}
.swiper-scrollbar{
    display: none;
}

.swiper-nav-btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 300px;
    position: absolute;
}

.prev-arrow{
    left: 0px;
    width: 120px;
    height: 120px;
    background: #E6EFF0;
    border-radius: 100px;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: -74px;
}
.next-arrow{
    right: 0px;
    width: 120px;
    height: 120px;
    font-size: 60px;
    background: #E6EFF0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: -74px;
}


.shape.one:not(.line),
.title.one,
.shape.two:not(.line),
.title.two,
.shape.three:not(.line),
.title.three,
.shape.four:not(.line),
.title.four,
.shape.five:not(.line),
.title.five,
.shape.six,
.title.six {
    opacity: 0;
}


@keyframes fadeIn {
    0% { opacity: 0; transform: translate(10%, 10%) scale(0.8);}
    100% { opacity: 1; transform: translate(0, 0) scale(1);}
}
@keyframes fadeInLine {
    0% { opacity: 0;}
    100% { opacity: 1;}
}

.title.one { animation: fadeIn 0.5s ease 0.5s forwards; }
.shape.dot.one { animation: fadeInLine 0.5s ease 0.4s forwards; }
.shape.one:not(.dot) { animation: fadeIn 0.5s ease 0.6s forwards; }
.title.two { animation: fadeIn 0.5s ease 1.1s forwards; }
.shape.dot.two { animation: fadeInLine 0.5s ease 1.1s forwards; }
.shape.two:not(.dot) { animation: fadeIn 0.5s ease 1.2s forwards; }
.title.three { animation: fadeIn 0.5s ease 1.7s forwards; }
.shape.dot.three { animation: fadeInLine 0.5s ease 1.7s forwards; }
.shape.three:not(.dot) { animation: fadeIn 0.5s ease 1.8s forwards; }
.title.four { animation: fadeIn 0.5s ease 2.3s forwards; }
.shape.dot.four { animation: fadeInLine 0.5s ease 2.3s forwards; }
.shape.four:not(.dot) { animation: fadeIn 0.5s ease 2.4s forwards; }
.title.five { animation: fadeIn 0.5s ease 2.9s forwards; }
.shape.dot.five { animation: fadeInLine 0.5s ease 2.9s forwards; }
.shape.five:not(.dot) { animation: fadeIn 0.5s ease 3s forwards; }
.title.six { animation: fadeIn 0.5s ease 3.5s forwards; }
.shape.dot.six { animation: fadeInLine 0.5s ease 3.5s forwards; }
.shape.six:not(.dot) { animation: fadeIn 0.5s ease 3.6s forwards; }

.shape.line{
    stroke: #555454;
    stroke-width: 2px;
}

.shape.line.one{
    stroke-dasharray: 280;
    stroke-dashoffset: 280;
    animation: drawLine 0.5s linear 0.6s forwards;
}
.shape.line.two{
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: drawLine 0.5s linear 1.2s forwards;
}
.shape.line.three{
    stroke-dasharray: 450;
    stroke-dashoffset: 450;
    animation: drawLine 0.5s linear 1.8s forwards;
}
.shape.line.four{
    stroke-dasharray: 380;
    stroke-dashoffset: 380;
    animation: drawLine 0.5s linear 2.4s forwards;
}
.shape.line.five{
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: drawLine 0.5s linear 3s forwards;
}

.project-card .content-img.mobile{
    display: none;
}

section#blank p{
    text-align: center;
}

.project-card .image{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    justify-content: center;
    margin-bottom: 12px;
}

.project-card .image img{
    height: auto;
    width: 530px;
    height: 530px;
}

.swiper-button-disabled{
    opacity: 0.4;
    cursor: default;
}


@keyframes drawLine {
    to {
      stroke-dashoffset: 0;
    }
}

.swiper-button-disabled{
    opacity: 0.4;
    cursor: default;
}

.swiper-scrollbar.swiper-scrollbar-horizontal{
    z-index: 0 !important;
}

@media screen and (max-width: 1756px) {
    .hero-solutions .background-pattern{
        margin-top: 100px;
    }

    .hero-solutions .left{
        height: 600px;
    }

    .content{
        margin-top: 10px;
    }

    .swiper-nav-btns{
        bottom: 400px;
    }

    .swiper-nav-btns .prev-arrow,
    .swiper-nav-btns .next-arrow{
        width: 80px;
        height: 80px;
    }

    .swiper-nav-btns .next-arrow{
        margin-right: -44px;
    }
    .swiper-nav-btns .prev-arrow{
        margin-left: -44px;
    }

    .swiper-nav-btns > div > img{
        width: 55px;
    }

    .project-card .title{
        font-size: 32px;
    }

    .project-card .image img{
        height: auto;
        width: 450px;
        height: 450px;
    }
}

@media screen and  (max-width: 1592px) {
    section#animated-projects .header{
        font-size: 40px;
    }

    .project-card .title{
        font-size: 28px;
    }

    .swiper-nav-btns{
        bottom: 300px;
    }

    .project-card .description{
        gap: 12px;
    }

    .content{
        font-size: 16px;
    }

    .project-card .description img{
        width: 120px;
    }

    .project-card .image img{
        height: auto;
        width: 400px;
        height: 400px;
    }
}

@media screen and  (max-width: 1572px) {
    .hero-solutions .background-pattern{
        margin-top: 70px;
    }

    .hero-solutions{
        padding-top: 80px;
        padding-left: 100px;
        padding-right: 100px;
    }

    .hero-solutions .left{
        height: 550px;
    }

    .hero-solutions .left p{
        font-size: 45px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 60px;
    }
}

@media screen and  (max-width: 1430px) {
    .content{
        font-size: 12px;
    }
}

@media screen and  (max-width: 1328px) {

    .hero-solutions .background-pattern{
        margin-top: 50px;
    }

    .hero-solutions .left p{
        font-size: 45px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 60px;
    }

    .products-background{
        height: 100%;
    }

    .swiper-nav-btns{
        bottom: 200px;
    }

    // .project-card .image img{
    //     height: auto;
    //     width: 350px;
    //     height: 350px;
    // }

}

@media screen and (max-width: 1368px) {
    section#animated-projects .header{
        font-size: 32px;
    }
    
}

@media screen and  (max-width: 1328px) {
    .content{
        font-size: 16px;
    }

    .swiper-nav-btns{
        bottom: 230px;
    }
}

@media screen and (max-width: 1224px) {
    .hero-solutions .left p:nth-child(2){
        font-size: 35px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 55px;
    }

    .hero-solutions .left{
        height: 500px;
    }

    .project-card .image img{
        width: 40%;
        height: auto;
    }
}

@media screen and (max-width: 1064px) {
    .hero-solutions .left p:nth-child(2){
        font-size: 30px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 50px;
    }

    .hero-solutions{
        padding-left: 100px;
        padding-right: 100px;
    }

    .hero-solutions .left{
        height: 400px;
    }

    .hero-solutions .background-pattern{
        margin-top: 50px;
    }
}

@media screen and (max-width: 964px) {
    section#animated-projects .header{
        font-size: 24px;
    }
}

@media screen and (max-width: 956px) {
    .hero-solutions .left p:nth-child(2){
        font-size: 27px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 45px;
    }
}

@media screen and (max-width: 876px) {
    .hero-solutions .left p:nth-child(2){
        font-size: 22px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 36px;
    }

    .hero-solutions .left{
        height: 270px;
    }
}

@media screen and (max-width: 784px) {
    section#animated-projects .header{
        font-size: 20px;
    }
}

@media screen and (max-width: 684px) {
    .hero-solutions{
        padding-left: 50px;
        padding-right: 50px;
    }

    .hero-solutions .left{
        height: 250px;
    }
}

@media screen and (max-width: 684px) {
    .hero-solutions .left p:nth-child(2){
        font-size: 18px;
    }

    .hero-solutions .left p:nth-child(1){
        font-size: 30px;
    }

    .hero-solutions .left{
        height: 160px;
    }
}

@media screen and  (max-width: 644px) {
    .content{
        font-size: 12px;
        text-align: justify;
    }

    .swiper-nav-btns{
        bottom: 170px;
    }

    section#animated-projects{
        padding-bottom: 70px;
    }

    section#blank{
        padding-top: 70px;
        margin-bottom: 70px;
    }   

    section#industries{
        margin-bottom: 50px;
    }

    .swiper-nav-btns .prev-arrow,
    .swiper-nav-btns .next-arrow{
        width: 60px;
        height: 60px;
    }

    .swiper-nav-btns .next-arrow{
        margin-right: -30px;
    }
    .swiper-nav-btns .prev-arrow{
        margin-left: -30px;
    }

    .swiper-nav-btns > div > img{
        width: 35px;
    }
}

@media screen and (max-width: 556px) {
    .project-card .content-img.mobile{
        display: flex;
        width: 100px;
    }

    .project-card .description img{
        display: none;
    }

    .project-card .title{
        font-size: 20px;
    }

    section#blank{
        padding-top: 50px;
        margin-bottom: 50px;
    }    
}

@media screen and (max-width: 500px) {
    .swiper-scrollbar{
        display: block;
    }
    .hero-solutions{
        padding-left: 30px;
        padding-right: 30px;
    }

    .hero-solutions .left{
        position: relative;
        order: 2;
        height: auto;
        margin-top: 20px;
    }

    .hero-solutions .left p{
        width: 100%;
        text-align: center;
    }

    .hero-solutions .background-pattern{
        margin-top: 32px;
    }

    section#animated-projects .header{
        text-align: center;
    }

    .swiper-nav-btns .prev-arrow,
    .swiper-nav-btns .next-arrow{
        width: 60px;
        height: 60px;
    }

    .swiper-nav-btns .next-arrow{
        margin-right: -30px;
    }
    .swiper-nav-btns .prev-arrow{
        margin-left: -30px;
    }

    .swiper-nav-btns > div > img{
        width: 35px;
    }

    .project-card .description img{
        width: 80px;
    }

    .swiper-nav-btns{
        bottom: 300px;
        display: none;
    }

    .swiper-scrollbar {
        position: relative;
        background: #d6d6d6;
        bottom: -10px;
    }
}