@import '/src/Styles.scss';

#offerings.carriers-section {
    .img{

        svg{
            overflow: visible;
        }
        @media screen and (max-width: 1328px) {
            display: none;
        }
    }
}

#hiring-process{
    padding-top: 0px;
}

.hero.carriers{
    .right{
        width: 50%;

        @media screen and (max-width: 1680px) {
            width: 40%;
        }
        @media screen and (max-width: 900px) {
            width: 30%;
        }
        @media screen and (max-width: 684px) {
            width: 100%;
            // display: flex;
            justify-content: center;

            img{
                max-width: 400px;
            }
        }
    }

    .left{
        @media screen and (max-width: 684px) {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1680px) {
    .contact-form#quotation{
        .button-box{
            justify-content: center;
        }
    }
}
@media screen and (max-width: 1024px) {
    .sub-title:nth-child(1){
        margin-top: 0px !important;
    }

    .we-re-hiring .sub-title{
        margin-top: 40px !important;
    }
    .we-re-hiring p:nth-child(2){
        margin-bottom: 0px !important;
    }
}

@media screen and (max-width: 490px) {
    .hero.carriers{
        padding-left: 40px;
        padding-right: 40px;

        .right {
            img{
                max-width: 250px;
            }
        }
    }
}


// .body{
//     font-family: "Poppins";
//   }
// //   .container {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       width: 100%;
    
      .main-c {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: space-between;
        align-items: space-between;
        width: 80%;
        margin-top: 4rem;
        margin-bottom: 3rem;
    
        .leftt {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          margin-left: 0;
          width: 50%;
    
          p:nth-child(1) {
              width: 100%;
            font-size: 4.5rem;
            color: #005f6a;
            font-weight: bold;
            margin: 0;
          }
    
          p:nth-child(2) {
            font-size: 3.5rem;
            color: #54949b;
            font-weight: bold;
            margin: 0.5rem 0;
          }
    
          span:nth-child(3),
          span:nth-child(4) {
            margin: 0.25rem 0;
            font-size: 1.3rem;
          }
        }
    
        .rightt {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          width: 50%;
    
          img {
            width: 80%;
            object-fit:contain;
          }
        }
    
        @media (max-width: 970px) {
          flex-direction: column;
          align-items: center;
    
          .leftt {
            margin: 0;
            margin-left: 1rem;
            width: 100%;
    
            span:nth-child(1) {
              font-size: 2.5rem;
            }
    
            span:nth-child(2) {
              font-size: 1.5rem;
              margin: 0.3rem 0;
            }
    
            span:nth-child(3),
            span:nth-child(4) {
              font-size: 1rem;
            }
    
            order: 1;
          }
    
          .rightt {
            width: 100%;
    
            img {
              width: 100%;
            }
          }
        }
      }
      .sub-title{
        font-size: 60px;
        color: #005f6a;
        // font-weight: bold;
        font-weight: 500;
        margin-bottom: 3rem;
        margin-top: 3rem;
        text-align: left;
    }
      .values{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: space-between;
          width: 80%;
          .content-block-row,.last-row{
              .block{
                  width:25%;
                  text-align: justify;
                  font-size: 1.3rem;
                  margin-bottom: 3rem;
                  img{
                      padding-left:3rem;
                      width: 80%;
                  }
              }
              p{
                  font-size: 1.8rem;
                  color: #005f6a;
                  font-weight:bold;
                  text-align: left;
              }
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
          }
          @media (max-width: 768px){
            align-items: center;
            font-size: 1rem;
                width: 100%;
  
  
            .content-block-row,.last-row{
              flex-direction: column;
              .block{
                width: 80%;
                text-align: center;
                margin-bottom: 1rem;
                p{
                  text-align: center;
                }
              }
            }
           
          }
  
      }
  
      .hiring{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
        width: 100%;
        .hiring-image{
          width: 100%;
          align-self: center;
          display: flex;
          align-items: center;
          justify-content:center;
          img{
            width:80%;
          }
        }
        .content-block-row,.last-row{
          .block{
              width:20%;
              text-align: center;
              font-size: 1.3rem;
              margin-bottom: 3rem;
          }
          p{
              font-size: 1.8rem;
              color: #005f6a;
            //   font-weight:bold;
              font-weight:500;
          }
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
      }
      @media (max-width: 768px){
        .content-block-row,.last-row{
          flex-direction: column;
          .block{
            width:80%;
            text-align: left;
            margin-bottom: 0;
            
          }
        }
      }
      }
      .we-re-hiring{
        width: 100%;
        display: flex;
        flex-direction: column;
        p:nth-child(2){
          margin-top: 0;
        //   font-size: 1.3rem;
          font-size: 20px;
          text-align: justify;
          margin-bottom: 3rem;
        }
      }
     
//   }

.css-ahj2mt-MuiTypography-root,
.css-1tpky2o-MuiTypography-root{
    font-family: 'Poppins' !important;
}
  
@media screen and (max-width: 1572px) {
    .sub-title{
        font-size: 40px;
    }
}
@media screen and (max-width: 1328px) {
    .sub-title{
        font-size: 28px;
        margin-bottom: 30px;
    }

    .hiring .content-block-row p{
        font-size: 20px;
    }

    .hiring .content-block-row .block,
    .we-re-hiring p:nth-child(2){
        font-size: 14px;
    }

    .css-ahj2mt-MuiTypography-root,
    .MuiTypography-body1{
        font-size: 14px !important;
    }
    .css-1tpky2o-MuiTypography-root,
    .MuiTypography-h5{
        font-size: 22px !important;
    }
}

@media screen and (max-width: 876px) {
    .hiring .content-block-row p{
        font-size: 16px;
    }

    .hiring .content-block-row .block,
    .we-re-hiring p:nth-child(2){
        font-size: 12px;
    }

    .css-ahj2mt-MuiTypography-root,
    .MuiTypography-body1{
        font-size: 12px !important;
    }
    .css-1tpky2o-MuiTypography-root,
    .MuiTypography-h5{
        font-size: 16px !important;
    }
}

@media screen and (max-width: 684px) {
    .sub-title{
        font-size: 22px;
    }
}

@media screen and (max-width: 432px) {
    .css-ahj2mt-MuiTypography-root,
    .MuiTypography-body1{
        font-size: 10px !important;
    }
    .css-1tpky2o-MuiTypography-root,
    .MuiTypography-h5{
        font-size: 14px !important;
    }
    .css-55vwbm-MuiTimeline-root,
    .MuiTimeline-root{
        padding: 0px !important;
    }

    .css-i4bv87-MuiSvgIcon-root,
    .MuiSvgIcon-root{
        font-size: 12px !important;
    }
    .css-ymt5it-MuiTypography-root-MuiTimelineOppositeContent-root{
        // padding: 0px !important;
    }
    .css-i6lby4-MuiTypography-root-MuiTimelineContent-root{
        // padding: 0px !important;
    }
}