.card-c{
   
   display: flex;
   width: 70%;
   flex-direction: column;
  margin-top: 3rem;
   //margin-left: 8rem;
  border-radius: 10px;
  margin-bottom: 2rem;
   justify-content: space-between;
  margin-left: 7.5%;
   height: auto;
    //margin: 4rem 0;
    padding:1rem 10rem;
    color: #005F6A;
    background-color:  #BAE9F8;
    font-size: 1.1rem;
    div{
        width: 90%;
    }
    div:nth-child(1){
        margin: 1rem 0;
       
    }

.second-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    .second-row-sub{
        div{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center; 
            align-items: center;
            margin: 0;

        }
    }
    div{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center; 
    }
}
.third-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
    input{
        margin: 0 0.5rem;
        border:none;
        background-color: #BAE9F8;
        border-bottom: 1px solid #005F6A;
        flex-grow: 1;
        text-align: center;
    }
    input:focus{
        outline: none;
        background-color: #BAE9F8;
      caret-color: #005F6A;
    }
    .button{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
       
      button{
        margin-top: 2rem;
        color: #FDF6EB;
        width:8rem;
        height: 2rem;
        border-radius: 0.4rem;
        border: 2.481px solid var(--Foundation-Green-green-400, #337F88);
        background: linear-gradient(270deg, #54949B 0%, #005F6A 99.02%);
      }
    }
    @media (max-width: 1024px) {
       
        padding: 1rem;
        height: auto;
    .card-c{
      margin-left: 2rem;
    }

    .second-row{
        flex-direction: column;
        align-items: space-evenly;
        .second-row-sub{
            display: flex;
            flex-direction: column;
            div{
                margin: 0.5rem 0;
            }
        }
      
    }
    .third-row{
    
        margin: 0.6rem 0;
     input{
        width: 40%;
     }
    }
       }
       .button{
        margin-top:1rem;
        align-items: center;
        justify-content: center;
       }
}
    @media (max-width: 395px) {
        .myname{
          font-size: 0.8rem;
        }
      .companynm{
         font-size: 0.8rem;
      }
      .iliketo{
        font-size: 0.8rem;
      }
      .reachme{
                font-size: 0.8rem;

      }
    }

.button button {
    cursor: pointer; /* Ensures the cursor indicates clickability */
    opacity: 1; /* Ensures the button is fully visible */
    pointer-events: auto; /* Ensures the button can be interacted with */
}