.section1-wwd{
    width: 100%;
    display: flex;
    height: 40rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.sec1-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 60mimrem;
}

.whatwedo{
    // margin-top: 4rem;
    margin-bottom: 0rem;
    font-size: 5rem;
    color: #005f6a;
    font-weight: bold;
}

.atphinexa{
     font-size: 4rem;
        color: #54949b;
        font-weight: bold;
        margin-top:0.01rem ;
}
.desc{
    //margin-left: 5.2rem;
    margin-top: 2rem;
    text-align: left;
    width: 27rem;
}


.whatwedoimg{
    height: 24rem;
    width: 24rem;
}

@media only screen and (max-width: 1000px) {

    .section1-wwd {
    flex-direction: column; // Stack the elements on smaller screens

        justify-content: center;
}

.sec1-1 {
    align-items: center;
    width: 80%; // Make the text container take more space
}

.whatwedo {
        // margin-top: 10rem; // Adjust the margin for better spacing

    font-size: 3rem; // Reduce the font size for smaller screens
}

.atphinexa {
    margin-top: 1rem; // Adjust the margin for better spacing
    margin-right: 0; // Center the heading
    font-size: 2rem;
}

.desc {
    margin: 1rem auto; // Center the description and reduce the margin
    width: 90%; // Make it wider to fit the screen
}

.whatwedoimg {
    width: 50%; // Make the image take more space proportionally
    height: auto; // Maintain aspect ratio
}

@media only screen and (max-width: 555px) {
    .whatwedo {
        margin-top: 5rem; // Adjust the margin for better spacing
    }
}

}
