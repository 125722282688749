@import '/src/Styles.scss';
.animated-offering {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    gap: 30px;
    padding: 0px 190px 0 190px;
    overflow-x: hidden;
}

.animated-offering img{
    max-width: 550px;
    max-width: 350px;
    height: auto;
    z-index: 0;
}

.animated-offering .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    max-width: 592px;
    text-align: start;
}

.animated-offering .content .title {
    font-size: 32px;
    font-weight: $semi-bold;
}

.animated-offering .content .description {
    font-size: 18px;
    font-weight: $regular;
}

.animated-offering{
    gap: 40px;
}
@media screen and  (max-width: 1572px) {
    .animated-offering{
        padding-left: 0px;
        padding-right: 0px;
    }
    section#animated-offerings{
        // height: 2000px;
        background-size: cover;
        overflow-x: hidden;
    }
}

@media screen and  (max-width: 1328px) {
    .animated-offering .content .title{
        font-size: 24px;
    }
    .animated-offering .content .description{
        font-size: 16px;
    }
    .animated-offering img{
        max-width: 400px;
    }
    section#animated-offerings{
        // height: 1600px;
    }
}

@media screen and (max-width: 1064px) {
    .animated-offering img{
        max-width: 350px;
    }

    section#animated-offerings{
        // height: 1400px;
        background-size: cover;
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 876px) {
    .animated-offering{
        gap: 20px;
    }
    .animated-offering .content{
        gap: 10px;
    }
    .animated-offering .content .title{
        font-size: 16px;
    }

    .animated-offering .content .description{
        font-size: 12px;
    }

    .animated-offering img{
        max-width: 300px;
    }

    section#animated-offerings{
        height: 1100px;
        
    }
}

@media screen and (max-width: 570px) {
    section#animated-offerings{
        height: 1300px;
    }
    .animated-offering{
        flex-direction: column;
    }
    .animated-offering img{
        max-width: 250px;
    }

    .animated-offering .content{
        width: 100%;
        order: 2;
    }
}